import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

import "../styles/layout/header.scss"

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  return (
    <header className="l-header">
      <Link to={`/`} className="l-header_logo_link">
        <img
          src={data.file.publicURL}
          alt={data.site.siteMetadata.title}
          className="l-header_logo"
        />
      </Link>
      <ul id="l-headerMenu" className="l-headerMenu">
        <li className="l-headerMenu_item">
          <Link to={`/`} activeClassName="active">
            トップ
          </Link>
        </li>
        <li className="l-headerMenu_item">
          <Link to={`/about/`} activeClassName="active">
            私たちについて
          </Link>
        </li>
        <li className="l-headerMenu_item">
          <Link to={`/company/`} activeClassName="active">
            会社案内
          </Link>
        </li>
        <li className="l-headerMenu_item">
          <Link to={`/business/`} activeClassName="active">
            事業内容
          </Link>
        </li>
        <li className="l-headerMenu_item">
          <Link to={`/recruit/`} activeClassName="active">
            採用情報
          </Link>
        </li>
        <li className="l-headerMenu_item">
          <Link to={`/contact/`} activeClassName="active">
            問い合わせ
          </Link>
        </li>
      </ul>
      <div className="l-hamburgerMenu">
        {/*3点リーダ*/}
        <a href="#l-headerMenu">
          <span />
          <span />
          <span />
        </a>
      </div>
    </header>
  )
}
