import React from "react"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"

export default ({ children, title, description, image }) => {
  return (
    <div>
      <SEO title={title} description={description} image={image} />
      <Header />
      {children}
      <Footer />
    </div>
  )
}
