import React from "react"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStaticQuery, Link, graphql } from "gatsby"

import "../styles/layout/footer.scss"
import define from "../config/define"

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "logo_dark.svg" }) {
      publicURL
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  return (
    <footer className="l-footer">
      <div className="l-footerContainer">
        <Link to={`/`} className="l-footerContainer_logo">
          <img
            src={data.file.publicURL}
            alt={data.site.siteMetadata.title}
            height="32px"
            width="96px"
          />
          <p>{define.corp.name}</p>
        </Link>
        {/*<div className="l-footerMenuContainer">*/}
        {/*  <ul className="l-footerMenu">*/}
        {/*    <li className="l-footerMenu_item">*/}
        {/*      <Link to={`/about/`}>about</Link>*/}
        {/*    </li>*/}
        {/*    <li className="l-footerMenu_item">*/}
        {/*      <Link to={`/company/`}>company</Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*  <ul className="l-footerMenu">*/}
        {/*    <li className="l-footerMenu_item">*/}
        {/*      <Link to={`/recruit/`}>recruit</Link>*/}
        {/*    </li>*/}
        {/*    <li className="l-footerMenu_item">*/}
        {/*      <Link to={`/contact/`}>contact</Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
        <div className="l-footerAddressContainer">
          <div className="l-footerAddress">{define.corp.postCode}</div>
          <div className="l-footerAddress">
            {define.corp.addr}
          </div>
          <div className="l-footerAddress">{define.corp.tel}</div>
        </div>
      </div>
      <div className="l-footerBottom">
        <small className="l-footerBottom_copyright">
          {define.corp.copyright}
        </small>
        {/*<div className="l-footerBottomSnsContainer">*/}
        {/*  <a className="l-footerBottomSns" href="https://ja-jp.facebook.com/">*/}
        {/*    <FontAwesomeIcon*/}
        {/*      color="#fffff"*/}
        {/*      icon={["fab", "facebook-f"]}*/}
        {/*      size="lg"*/}
        {/*    />*/}
        {/*  </a>*/}
        {/*  <a className="l-footerBottomSns" href="https://twitter.com">*/}
        {/*    <FontAwesomeIcon*/}
        {/*      color="#fffff"*/}
        {/*      icon={["fab", "twitter"]}*/}
        {/*      size="lg"*/}
        {/*    />*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    </footer>
  )
}
