import React from "react";

export default {
  corp: {
    name: "株式会社プラミナス",
    name_en: "PLMIN.US CO.,LTD",
    ceo: "清水 裕之",
    no: "7010501039521",
    tel: "03-4500-9669",
    capital: "1,000,000円（2017年12月末現在）",
    postCode: "〒111-0056",
    addr: "東京都台東区小島2-20-11 LIGビル 1F",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d809.948764905828!2d139.78168441800486!3d35.70666052988956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc79124fd6f1cdf4b!2z44GE44GE44Kq44OV44Kj44K55LiK6YeOIGJ5IExJRw!5e0!3m2!1sja!2sjp!4v1619588614285!5m2!1sja!2sjp",
    mapShort: "https://goo.gl/maps/wSRsxPJ82aERNFxH7",
    copyright: "© 2021 PLMIN.US CO.,Ltd.",
    histories: [
      {
        date: "2012・05",
        desc: "プラミナス設立"
      },
      {
        date: "2016・05",
        desc: "株式会社プラミナス設立"
      },
      {
        date: "2018・12",
        desc: "会社住所を「東京都台東区小島2-20-11 LIGビル 1F」に移転"
      },
    ],
    notices: [
      {
        date: "2021.04.23",
        desc: "ホームページをリニューアルしました"
      },
      {
        date: "2021.09.01",
        desc: "本社の電話番号を『03-4405-6924』から『03-4500-9669』変更しました"
      },
    ]
  }
}