import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription
      }
    }
    file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
  }
`

export default (props) => {
  const { site, file } = useStaticQuery(query)
  const seo = {
    defaultTitle: site.siteMetadata.defaultTitle,
    titleTemplate: site.siteMetadata.titleTemplate,
    image: props.image || file.publicURL,
    title: props.title,
    description: props.description || site.siteMetadata.defaultDescription,
  }

  return (
    <Helmet
      htmlAttributes={{ lang: "ja" }}
      title={seo.title}
      defaultTitle={seo.defaultTitle}
      titleTemplate={seo.titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
    </Helmet>
  )
}
